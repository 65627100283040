import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
 
class MainBanner extends React.Component {
    render(){
        return (
            <div id="home" className="main-banner item-bg-one">
                <div className="d-table">
                    <div className="d-table-cell">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 col-md-12">
                                    <div className="main-banner-text">
                                        <h1>Your Professional, Periodical, </h1>
                                        <h1><span>Cleaning Experts</span></h1>
                                        <p>with over 25 years of combined cleaning experience throughout Metro Melbourne.</p>
                                        <AnchorLink href="#contact" className="btn btn-primary view-work">Contact Us</AnchorLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
 
export default MainBanner;