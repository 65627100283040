import React from 'react';
import 'isomorphic-fetch';
import emailjs from "@emailjs/browser";
 
class Contact extends React.Component {
    state = {
        submitting: false,
        submitted: false,
        error: false,
        buttonState: '',
        formFields: {
            name: '',
            email: '',
            phone: '',
            text: ''
            //agree: ''
        }
    };

    onSubmit = (e) => {
        e.preventDefault();
        //const data = this.state.formFields;
        emailjs.sendForm(
            'service_o4p8cjz',
            'template_d61k1h8',
            e.target, // <- Replace your state with this params object that contains the g-recaptcha-response key/value with the token retrieved.
            'Zz4jjmB_ya59J9zNO'
          ).then(res => {
            if (res.status === 200) {
                this.setState({ submitted: true })
            }
            let formFields = Object.assign({}, this.state.formFields);
            formFields.name = '';
            formFields.email = '';
            formFields.phone = '';
            formFields.text = '';
           //formFields.agree = '';
            this.setState({formFields});
        }).catch(err => {this.setState({ error: true }); console.log('There was an error:' + err.message)});
    }

    nameChangeHandler = (e) => {
        let formFields = Object.assign({}, this.state.formFields);
        formFields.name = e.target.value;
        this.setState({formFields});
    }

    emailChangeHandler = (e) => {
        let formFields = Object.assign({}, this.state.formFields);
        formFields.email = e.target.value;
        this.setState({formFields});
    }

    phoneChangeHandler = (e) => {
        let formFields = Object.assign({}, this.state.formFields);
        formFields.phone = e.target.value;
        this.setState({formFields});
    }

    textChangeHandler = (e) => {
        let formFields = Object.assign({}, this.state.formFields);
        formFields.text = e.target.value;
        this.setState({formFields});
    }

    /* agreeChangeHandler = (e) => {
        let formFields = Object.assign({}, this.state.formFields);
        formFields.agree = e.target.value;
        this.setState({formFields});
    }*/

    onHideSuccess = () => {
        this.setState({submitted: false});
        this.setState({error: false});
    }

    successMessage = () => {
        if (this.state.submitted){
            return (
                <div 
                    className="alert alert-success alert-dismissible fade show" 
                    style={{ marginTop: '20px', marginBottom: '0' }}
                >
                    <strong>Thank you!</strong> Your message have been sent successfully.
                    <button 
                        type="button" 
                        className="btn-close"
                        onClick={this.onHideSuccess}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            );
        } else if (this.state.error) {
            return (
                <div 
                    className="alert alert-danger alert-dismissible fade show" 
                    style={{ marginTop: '20px', marginBottom: '0' }}
                >
                    <strong>Error!</strong> Your message was not sent, please try again later.
                    <button 
                        type="button" 
                        className="btn-close"
                        onClick={this.onHideSuccess}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            );
        }
    }

    render(){
        return (
            <section id="contact" className="contact-area ptb-80 bg-f6f6f6">
                <div className="container">
                    <div className="section-title">
                        <h4>Get in Touch</h4>
                        <h2><span>Contact</span> Us</h2>
                    </div>
                    
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <iframe 
                                title="Google Map" 
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d201780.9723678038!2d144.9909554618604!3d-37.79407766176524!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad646b5d2ba4df7%3A0x4045675218ccd90!2sMelbourne%20VIC!5e0!3m2!1sen!2sau!4v1691060309800!5m2!1sen!2sau&z=10" 
                                width="100%" 
                                height="392" 
                                loading="lazy"
                            ></iframe>
                            
                            <div className="contact-info">
                                <ul>
                                    <li>
                                        <i className="fa fa-map-marker"></i> Greater Melbourne
                                    </li>
                                    <li>
                                        <i className="fa fa-envelope"></i> 
                                        <a href="mailto:info@see-throughcommercial.com.au" class="email">info@see-throughcommercial.com.au</a>
                                    </li>
                                    <li>
                                        <i className="fa fa-phone"></i> 
                                        <a href="tel:+61468576626" class="phone">(+61) 0468 576 626</a>
                                    </li>
                                    <li>
                                        <a href="https://www.facebook.com/profile.php?id=100090100336571" target="_blank" rel="noopener noreferrer" aria-label="See-Through Commercial Cleaning Facebook Link">
                                            <i className="fa fa-facebook"></i>
                                        </a>
                                        <a href="https://www.instagram.com/seethroughcommercial/?igshid=MzRIODBiNWFIZA%3D%3D" target="_blank" rel="noopener noreferrer" aria-label="See-Through Commercial Cleaning Instagram Link">
                                            <i className="fa fa-instagram i_flex"></i>
                                        </a><br />
                                    </li>
                                  {/*  <li>
                                        <i className="fa fa-fax"></i> 
                                        <a href="tel:000-000-00000">0000 0000</a>
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                        
                        <div className="col-lg-6 col-md-12">
                            <div className="contact-form">
                                <h4>Request a Quote</h4>
                                <form id="contactForm" onSubmit={this.onSubmit}>
                                    <div className="row">
                                        <div className="col-lg-12 col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="name">Name</label>
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    name="name" 
                                                    id="name" 
                                                    required={true}
                                                    data-error="Please enter your name" 
                                                    value={this.state.formFields.name}
                                                    onChange={this.nameChangeHandler}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="email">Email</label>
                                                <input 
                                                    type="email" 
                                                    className="form-control" 
                                                    name="email" 
                                                    id="email" 
                                                    required={true}
                                                    data-error="Please enter your email" 
                                                    value={this.state.formFields.email}
                                                    onChange={this.emailChangeHandler}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <label htmlFor="number">Phone Number</label>
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    name="number" 
                                                    id="number" 
                                                    required={true}
                                                    data-error="Please enter your number" 
                                                    value={this.state.formFields.phone}
                                                    onChange={this.phoneChangeHandler}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <label htmlFor="message">Message</label>
                                                <textarea 
                                                    name="message" 
                                                    className="form-control" 
                                                    id="message" 
                                                    cols="30" 
                                                    rows="4" 
                                                    required={true}
                                                    data-error="Write your message" 
                                                    value={this.state.formFields.text}
                                                    onChange={this.textChangeHandler}
                                                />
                                            </div>
                                        </div>
                                        
                                        {/*
                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <input 
                                                    type="checkbox"
                                                    id="agree"
                                                    required={true}
                                                    onChange={this.agreeChangeHandler}
                                                />
                                                <label htmlFor="agree">I agree to be contacted by see-throughcommercial cleaning to discuss this quote.</label>
                                            </div>
                                        </div>
                                        */}
                                        <div className="col-lg-12 col-md-12">
                                            <button type="submit" className="btn btn-primary">Send Message</button>
                                            {this.successMessage()} 
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default Contact;