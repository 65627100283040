import React from 'react';
import { Link } from 'react-router-dom';

 
const Ctr = () => {
    return (
        <section className="ctr-area ptb-80">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        <div className="section-title mb-0">
                            <Link to={{ pathname: "https://see-throughpropertyservices.com.au" }} target="_blank" rel="noopener noreferrer">
                                <picture>
                                    {/*<source type="image/avif" srcSet={require('../../assets/images/See-Through-Property-Services.avif')} />*/}   
                                    <img src={require('../../assets/images/See-Through-Property-Services.png')} alt="See-Through Property Services" />
                                </picture>
                            </Link>
                            <h2>Partnered with See-Through Property Services</h2>
                            <p>With a combined experience of over 30 years, you can trust that your job will be completed to the highest standard.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
 
export default Ctr;