import React from 'react'
import MainBanner from '../PageBanners/MainBannerOne';
import Work from '../Common/Work';
import Testimonial from '../Common/Testimonial';
import Partner from '../Common/Partner';
import Contact from '../Common/Contact';
import Footer from '../Common/Footer';
import GoTop from '../Common/GoTop';
import Ctr from '../Common/Ctr';
 
class HomeOne extends React.Component {
    render(){
        return (
            <>
                {/* Main Banner */}
                <MainBanner />

                {/* Work Area */}
                <Work />

                {/* Testimonials Area */}
                <Testimonial />

                {/* Partner Area */}
                <Partner />

                {/* CTR Area */}
                <Ctr />

                {/* Contact Area */}
                <Contact />

                {/* Footer Area */}
                <Footer />
                <GoTop scrollStepInPx="150" delayInMs="16.66" />
            </>
        );
    }
}
 
export default HomeOne;