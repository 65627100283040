import React from 'react';
import OwlCarousel from 'react-owl-carousel3';
import clientImgOne from '../../assets/images/gslps.jpg';
import clientImgTwo from '../../assets/images/seymour_construction.jpg';
import clientImgThree from '../../assets/images/jointly.jpg';
import clientImgFour from '../../assets/images/Emajo_Barthelson.jpg';
import clientImgFourAvif from '../../assets/images/Emajo_Barthelson.avif';

const options = {
    loop: true,
    autoplay:true,
    nav: true,
    margin: 30,
    mouseDrag: true,
    autoplayHoverPause: true,
    responsiveClass: true,
    dots: true,
    lazyLoad: true,
    navText: [
        "<i class='fa fa-angle-left'></i>",
        "<i class='fa fa-angle-right'></i>"
    ],
    responsive: {
        0: {
            items: 1
        },
        576: {
            items: 2
        },
        768: {
            items: 2
        },
        1024: {
            items: 3
        }
    }
}

class Testimonial extends React.Component {
    render(){
        return (
            <section id="testimonal" className="testimonials-area ptb-80 bg-f6f6f6">
                <div className="container">
                    <div className="section-title">
                        <h2>Customer <span>Feedback</span></h2>
                    </div>
                    
                    <div className="row">
                        <OwlCarousel 
                            className="team-slider owl-carousel owl-theme"
                            {...options}
                        >
                            <div className="col-lg-12">
                                <div className="single-feedback">
                                    <div className="client-info">
                                        <div className="client-pic">
                                            <img src={clientImgOne} alt="Good Shepherd Lutheran P/S" />
                                        </div>

                                        <div className="client-title">
                                            <h4>Trevor Stamford</h4>
                                            <h5>Facilities Manager </h5>
                                            <h5>Good Shepherd Lutheran P/S</h5>
                                        </div>
                                    </div>

                                    <p>"The canteen ladies were very happy with their new clean floor, great job."</p>

                                    <span><i className="fa fa-quote-left"></i></span>
                                </div>
                            </div>
                                
                            <div className="col-lg-12">
                                <div className="single-feedback">
                                    <div className="client-info">
                                        <div className="client-pic">
                                            <img src={clientImgTwo} alt="Seymour Construction" />
                                        </div>

                                        <div className="client-title">
                                            <h4>Don Seymour</h4>
                                            <h5>Owner</h5>
                                            <h5>Seymour Construction</h5>
                                        </div>
                                    </div>

                                    <p>"Reliable, efficient, commercial builders cleans are usually a little more tricky especially with Stainless Steel involved by the team did great!"</p>

                                    <span><i className="fa fa-quote-right"></i></span>
                                </div>
                            </div>
                            
                            <div className="col-lg-12">
                                <div className="single-feedback">
                                    <div className="client-info">
                                        <div className="client-pic">
                                            <img src={clientImgThree} alt="Jointly Built" />
                                        </div>

                                        <div className="client-title">
                                            <h4>Brad Lenger</h4>
                                            <h5>Site Supervisor</h5>
                                            <h5>Jointly Built</h5>
                                        </div>
                                    </div>

                                    <p>"The team were efficient, thorough and exceeded our expectations."</p>

                                    <span><i className="fa fa-quote-left"></i></span>
                                </div>
                            </div>
                        
                            <div className="col-lg-12">
                                <div className="single-feedback">
                                    <div className="client-info">
                                        <div className="client-pic">
                                            <picture>
                                                <source type="image/avif" srcSet={clientImgFourAvif} />
                                                <img src={clientImgFour} alt="Ringwood Private Hospital" />
                                            </picture>
                                        </div>

                                        <div className="client-title">
                                            <h4>Emajo Barthelson</h4>
                                            <h5>General Services Manager</h5>
                                            <h5>Ringwood Private Hospital</h5>
                                        </div>
                                    </div>

                                    <p>"We are very happy with our recent floor scrubbing work, noticeable change and positive feedback from patients and staff."</p>

                                    <span><i className="fa fa-quote-left"></i></span>
                                </div>
                            </div>
                            {/*    
                            <div className="col-lg-12">
                                <div className="single-feedback">
                                    <div className="client-info">
                                        <div className="client-pic">
                                            <img src={clientImgTwo} alt="client-avatar" />
                                        </div>

                                        <div className="client-title">
                                            <h4>Alex Smith</h4>
                                            <h5>CEO of Twitter</h5>
                                        </div>
                                    </div>

                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet.</p>

                                    <span><i className="fa fa-quote-right"></i></span>
                                </div>
                            </div>
                                
                            <div className="col-lg-12">
                                <div className="single-feedback">
                                    <div className="client-info">
                                        <div className="client-pic">
                                            <img src={clientImgThree} alt="client-avatar" />
                                        </div>

                                        <div className="client-title">
                                            <h4>Olivia Smith</h4>
                                            <h5>CEO of Envytheme</h5>
                                        </div>
                                    </div>

                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet.</p>

                                    <span><i className="fa fa-quote-left"></i></span>
                                </div>
                            </div> */}
                        </OwlCarousel>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default Testimonial;