import React from 'react';
//import Lightbox from "react-image-lightbox";
//import 'react-image-lightbox/style.css';
//import { Link } from 'react-router-dom';
import mixitup from "mixitup";

const images = [
    {
        image: require("../../assets/images/projects/OfficeCleaning.avif"),
        image_jpg: require("../../assets/images/projects/OfficeCleaning.jpg"),
        title: "Office Cleaning"
    },
    {
        image: require("../../assets/images/projects/CarParkCleaning.avif"),
        image_jpg: require("../../assets/images/projects/CarParkCleaning.jpg"),
        title: "Car Park leaning"
    }, 
    {
        image: require("../../assets/images/projects/FacadeCleaning.avif"),
        image_jpg: require("../../assets/images/projects/FacadeCleaning.jpg"),
        title: "Facade Cleaning"
    }, 
    {
        image: require("../../assets/images/projects/EmergencyFloodWork.avif"),
        image_jpg: require("../../assets/images/projects/EmergencyFloodWork.jpg"),
        title: "Emergency FloodWork"
    },
    {
        image: require("../../assets/images/projects/StripandSeal.avif"),
        image_jpg: require("../../assets/images/projects/StripandSeal.jpg"),
        title: "Strip and Seal"
    },
    {
        image: require("../../assets/images/projects/PressureCleaning.avif"),
        image_jpg: require("../../assets/images/projects/PressureCleaning.jpg"),
        title: "Pressure Cleaning"
    },
    {
        image: require("../../assets/images/projects/StoneandTileCleaning.avif"),
        image_jpg: require("../../assets/images/projects/StoneandTileCleaning.jpg"), 
        title: "Stone and Tile Cleaning"
    },
    {
        image:  require("../../assets/images/projects/CarpetSteamClean.avif"),
        image_jpg: require("../../assets/images/projects/CarpetSteamClean.jpg"),
        title: "Carpet Steam Clean"
    },
    {
        image: require("../../assets/images/projects/WindowCleaning.avif"),
        image_jpg: require("../../assets/images/projects/WindowCleaning.jpg"),
        title: "Window Cleaning"
    },
    {
        image: require("../../assets/images/projects/GrafittiRemoval.avif"),
        image_jpg: require("../../assets/images/projects/GrafittiRemoval.jpg"),
        title: "Grafitti Removal"
    },
    {
        image: require("../../assets/images/projects/GarbageChuteCleaning.avif"),
        image_jpg: require("../../assets/images/projects/GarbageChuteCleaning.jpg"),
        title: "Garbage Chute Cleaning"
    },
    {
        image: require("../../assets/images/projects/andMore.avif"),
        image_jpg: require("../../assets/images/projects/andMore.jpg"),
        title: "andMore"
    }
];
 
class Work extends React.Component {

    componentDidMount() {
        mixitup("#mix-wrapper", {
            animation: {
                effects: "fade rotateZ(0deg)",
                duration: 700
            },
            classNames: {
                block: "programs",
                elementFilter: "filter-btn",
            },
            selectors: {
                target: ".mix-target"
            }
        });
    }

    render(){
        return (
            <section id="work" className="work-area ptb-80">
                <div className="container">
                    <div className="section-title">
                        <h2><span>Services</span> We Offer</h2>
                        <p>Hover or select to learn more </p>
                    </div>
                    
                    
                {/* <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="shorting-menu">
                                <button 
                                    className="filter" 
                                    data-filter="all"
                                >
                                    All
                                </button>
                                <button 
                                    className="filter" 
                                    data-filter=".brand"
                                >
                                    Brand
                                </button>
                                <button 
                                    className="filter" 
                                    data-filter=".design"
                                >
                                    Design
                                </button>
                                <button 
                                    className="filter" 
                                    data-filter=".graphic"
                                >
                                    Graphic
                                </button>
                                <button 
                                    className="filter" 
                                    data-filter=".photoshop"
                                >
                                    Photoshop
                                </button>
                                <button 
                                    className="filter"
                                    data-filter=".illustrator"
                                >
                                    Illustrator
                                </button>
                            </div>
                        </div>
                    </div> */}
                </div> 
                
                <div className="shorting">
                    <div className="row m-0" id="mix-wrapper">
                        <div 
                            className="col-lg-3 col-sm-6 mix brand p-0 mix-target flip-card"
                        >
                            <div className='flip-card-inner'>
                            <div className="single-work flip-card-front">
                                <picture>
                                    <source type="image/avif" srcSet={images[0].image} />
                                    <img src={images[0].image_jpg} alt="Office Cleaning" />
                                </picture>
                            </div>
                            <div className="work-content flip-card-back">
                                    <h4>Office Cleaning</h4>
                                    <br />
                                    <p>Your office is more than just a place to work; it's a reflection of your brand's professionalism and attention to detail. Ensure that every corner of your workspace shines with our high-quality office cleaning services.</p>
                            </div>
                            </div>
                        </div>

                        <div 
                            className="col-lg-3 col-sm-6 mix design p-0 mix-target flip-card"
                        >
                        <div className='flip-card-inner'>
                            <div className="single-work flip-card-front">
                                <picture>
                                    <source type="image/avif" srcSet={images[1].image} />
                                    <img src={images[1].image_jpg} alt="Car Park Cleaning" />
                                </picture>
                            </div>
                            <div className="work-content flip-card-back">
                                    <h4>Car Park Cleaning</h4><br />
                                    <p>See-Through Commercial Cleaning offers an exceptional car park deep cleaning service, ensuring pristine and spotless parking areas. Our team utilises cutting-edge equipment and processes to provide solutions to remove dirt, stains, grease and debris, leaving your car park impeccably clean.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6 mix graphic p-0 mix-target flip-card">
                        <div className='flip-card-inner'>
                            <div className="single-work flip-card-front">
                                <picture>
                                    <source type="image/avif" srcSet={images[2].image} /> 
                                    <img src={images[2].image_jpg} alt="Facade Cleaning" />
                                </picture>

                            </div>
                            <div className="work-content flip-card-back">
                                    <h4>Facade Cleaning</h4><br />
                                    <p>We offer professional facade cleaning services designed to enhance the exterior appearance of commercial buildings. We understand the importance of maintaining a clean and attractive exterior for your business, and our services are tailored to meet your specific needs.</p>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-lg-3 col-sm-6 mix photoshop p-0 mix-target flip-card">
                        <div className='flip-card-inner'>
                            <div className="single-work flip-card-front">
                                <picture>
                                    <source type="image/avif" srcSet={images[3].image} />
                                    <img src={images[3].image_jpg} alt="Emergency FloodWork" />
                                </picture>

                            </div>
                            <div className="work-content flip-card-back">
                                    <h4>Emergency FloodWork</h4><br />
                                    <p>See-Through Commercial Cleaning specialises in transparent and effective emergency flood work services, combining quick response times, advanced technology, skilled personnel, and personalised solutions to address clients' needs during flood emergencies.</p>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-lg-3 col-sm-6 mix design p-0 mix-target flip-card">
                        <div className='flip-card-inner'>
                            <div className="single-work flip-card-front">
                                <picture>
                                    <source type="image/avif" srcSet={images[4].image} />
                                    <img src={images[4].image_jpg} alt="Strip and Seal" />
                                </picture>

                            </div>
                            <div className="work-content flip-card-back">
                                    <h4>Strip and Seal</h4><br />
                                    <p>Strip and sealing service is a maintenance solution for commercial spaces, such as retail stores, offices, or warehouses. It involves removing old floor finish, cleaning, and applying a new, durable sealant that enhances the aesthetics of the space. Providing protection against wear and tear, and ensures a long-lasting and easy-to-maintain floor finish, making it an ideal choice for businesses seeking both functionality and visual appeal.</p>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-lg-3 col-sm-6 mix illustrator p-0 mix-target flip-card">
                        <div className='flip-card-inner'>
                            <div className="single-work flip-card-front">
                                <picture>
                                    <source type="image/avif" srcSet={images[5].image} />
                                    <img src={images[5].image_jpg} alt="Pressure Cleaning" />
                                </picture>

                            </div>
                            <div className="work-content flip-card-back">
                                    <h4>Pressure Cleaning</h4><br />
                                    <p>Pressure cleaning, also known as pressure or power washing, is a cleaning method that uses a high-pressure stream of water to remove dirt, grime, mold, mildew, and other contaminants from surfaces such as buildings, driveways, decks, and vehicles. It is an effective and efficient way to restore the appearance and cleanliness of various surfaces.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-sm-6 mix illustrator p-0 mix-target flip-card">
                        <div className='flip-card-inner'>
                            <div className="single-work flip-card-front">
                                <picture>
                                    <source type="image/avif" srcSet={images[6].image} />
                                    <img src={images[6].image_jpg} alt="Stone and Tile Cleaning" />
                                </picture>

                            </div>
                            <div className="work-content flip-card-back">
                                    <h4>Stone and Tile Cleaning</h4><br />
                                    <p>Stone and tile cleaning is a specialised cleaning process designed to remove dirt, stains, and grime from various types of stone and tile surfaces, such as marble, granite, ceramic, and porcelain. It typically involves using specific cleaning agents and techniques to protect the integrity and appearance of the materials while restoring their original lustre and cleanliness. </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-sm-6 mix illustrator p-0 mix-target flip-card">
                        <div className='flip-card-inner'>
                            <div className="single-work flip-card-front">
                                <picture>
                                    <source type="image/avif" srcSet={images[7].image} />
                                    <img src={images[7].image_jpg} alt="Carpet Steam Clean" />
                                </picture>

                            </div>
                            <div className="work-content flip-card-back">
                                    <h4>Carpet Steam Clean</h4><br />
                                    <p>Carpet steam cleaning, also known as hot water extraction, is a method of deep cleaning carpets. It involves spraying hot water mixed with detergent onto the carpet, then immediately extracting it using a powerful vacuum. This process helps remove dirt, stains, and allergens from the carpet fibres, leaving them clean and refreshed. </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-sm-6 mix illustrator p-0 mix-target flip-card">
                        <div className='flip-card-inner'>
                            <div className="single-work flip-card-front">
                                <picture>
                                    <source type="image/avif" srcSet={images[8].image} />
                                    <img src={images[8].image_jpg} alt="Window Cleaning" />
                                </picture>

                            </div>
                            <div className="work-content flip-card-back">
                                    <h4>Window Cleaning</h4><br />
                                    <p>Commercial window cleaning uses a specialised tool called a squeegee, typically with a rubber blade, to remove dirt, grime, and water from glass surfaces. It provides streak-free and spotless windows, enhancing the overall appearance of a building.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-sm-6 mix illustrator p-0 mix-target flip-card">
                        <div className='flip-card-inner'>
                            <div className="single-work flip-card-front">
                                <picture>
                                    <source type="image/avif" srcSet={images[9].image} />
                                    <img src={images[9].image_jpg} alt="Grafitti Removal" />
                                </picture>

                            </div>
                            <div className="work-content flip-card-back">
                                    <h4>Grafitti Removal</h4><br />
                                    <p>Graffiti removal cleaning is the process of eliminating unauthorized or unwanted graffiti from various surfaces, such as walls, buildings, or public spaces. It typically involves the use of specialized cleaning agents, power washing, scrubbing, or sandblasting to restore the affected surface to its original state.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-sm-6 mix illustrator p-0 mix-target flip-card">
                        <div className='flip-card-inner'>
                            <div className="single-work flip-card-front">
                                <picture>
                                    <source type="image/avif" srcSet={images[10].image} />
                                    <img src={images[10].image_jpg} alt="Garbage Chute Cleaning" />
                                </picture>

                            </div>
                            <div className="work-content flip-card-back">
                                    <h4>Garbage Chute Cleaning</h4><br />
                                    <p>Garbage chute cleaning with the use of our specialised tool involves the use of water pressure to thoroughly clean the interior of a garbage chute. The spinning tool that rotates rapidly coupled with correct cleaning chemicals dislodges and removes built-up debris, grime, and odours from the chute walls. The process ensures effective sanitation and maintenance of the chute, promoting a cleaner and more hygienic environment in residential or commercial buildings.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-sm-6 mix illustrator p-0 mix-target flip-card">
                        <div className='flip-card-inner'>
                            <div className="single-work flip-card-front">
                                <picture>
                                    <source type="image/avif" srcSet={images[11].image} />
                                    <img src={images[11].image_jpg} alt="Enquire about our other services" />
                                </picture>

                            </div>
                            <div className="work-content flip-card-back">
                                    <h4>AND MORE...</h4><br />
                                    <p>We are able to facilitate many more services! Please reach out to us and we can provide a quote!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default Work;