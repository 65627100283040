import React from 'react';
import { Link } from 'react-router-dom';
 
class Footer extends React.Component {
    render(){
        return (
            <footer className="footer-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <h4><Link to="/">See-Through Commercial Cleaning</Link></h4>
                            <ul>
                                <li><Link to={{ pathname: "https://www.facebook.com/profile.php?id=100090100336571" }} className="fa fa-facebook" target="_blank" rel="noopener noreferrer" aria-label="See-Through Commercial Cleaning Facebook Link"></Link></li>
                                <li><Link to={{ pathname: "https://www.instagram.com/seethroughcommercial/?igshid=MzRIODBiNWFIZA%3D%3D" }} className="fa fa-instagram" target="_blank" rel="noopener noreferrer" aria-label="See-Through Commercial Cleaning Instagram Link"></Link></li>
                            </ul>
                            <p>Copyright <i className="fa fa-copyright"></i> 2023 All Rights Reserved.</p>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}
 
export default Footer;