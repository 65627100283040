import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { withRouter, Link } from 'react-router-dom';

import logo from '../../assets/images/Logo_Navy.png';
 
class Navigation extends React.Component {
    state = {
        collapsed: true,
    };

    toggleNavbar = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    }

    componentDidMount() {
        let elementId = document.getElementById("navbar");
        document.addEventListener("scroll", () => {
            if (window.scrollY > 170) {
                elementId.classList.add("is-sticky");
                window.history.pushState("", document.title, window.location.pathname);
            } else {
                elementId.classList.remove("is-sticky");
            }
        });
        window.scrollTo(0, 0);

        let mainNavLinks = document.querySelectorAll("nav ul li a");
                
        window.addEventListener("scroll", () => {
            let fromTop = window.scrollY;
                
            mainNavLinks.forEach(link => {
                if (!(link.classList.contains('custom-fb') || link.classList.contains('custom-ig'))) {    
                let section = document.querySelector(link.hash);
                    if (
                        section.offsetTop <= fromTop &&
                        section.offsetTop + section.offsetHeight > fromTop
                    ) {
                        link.classList.add("active");
                    } else {
                        link.classList.remove("active");
                    }
                }
            });
        });
    }

    goToId = (e) => {
        window.location.hash = e;
        window.location.refresh(true);
    }

    renderMenus = () => {
        return (
            <ul className="navbar-nav ms-auto">
                <li className="nav-item">
                    <AnchorLink onClick={this.toggleNavbar} offset={() => 85} className="nav-link" href="#home">Home</AnchorLink>
                </li>
                <li className="nav-item">
                    <AnchorLink onClick={this.toggleNavbar} offset={() => 40} className="nav-link" href="#work">Services</AnchorLink>
                </li>
                <li className="nav-item">
                    <AnchorLink onClick={this.toggleNavbar} offset={() => 40} className="nav-link" href="#testimonal">Feedback</AnchorLink>
                </li>
                <li className="nav-item">
                    <AnchorLink onClick={this.toggleNavbar} offset={() => 40} className="nav-link" href="#contact">Contact</AnchorLink>
                </li>
                <li className="nav-item">
                    <Link to={{ pathname: "https://www.facebook.com/profile.php?id=100090100336571" }} className="nav-link custom-fb fa fa-facebook" target="_blank" rel="noopener noreferrer"></Link>
                </li>            
                <li className="nav-item">
                    <Link to={{ pathname: "https://www.instagram.com/seethroughcommercial/?igshid=MzRIODBiNWFIZA%3D%3D" }} className="nav-link custom-ig fa fa-instagram" target="_blank" rel="noopener noreferrer"></Link>
                </li>
            </ul>
        );
    }

    render(){
        const { collapsed } = this.state;
        const classOne = collapsed ? 'collapse navbar-collapse' : 'navbar-collapse collapse show';
        const classTwo = collapsed ? 'navbar-toggler navbar-toggler-right collapsed' : 'navbar-toggler navbar-toggler-right';
        return (
            <nav id="navbar" className="navbar navbar-expand-md navbar-light bg-light header-sticky">
                <div className="container">
                    <a 
                        className="navbar-brand" 
                        href="/"
                    >
                    {/* <img style={{ width: "175px", height: "60px" }} src={logo} alt="See-Through Commercial Cleaning" /> */}
                    <img style={{ width: "200px", height: "69px" }} src={logo} alt="See-Through Commercial Cleaning" />
                    </a>

                    <button 
                        onClick={this.toggleNavbar}
                        className={classTwo} 
                        type="button" 
                        data-toggle="collapse" 
                        data-target="#navbarSupportedContent" 
                        aria-controls="navbarSupportedContent" 
                        aria-expanded="false" 
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className={classOne} id="navbarSupportedContent">
                        {this.renderMenus()}
                    </div>
                </div>
            </nav>
        );
    }
}
 
export default withRouter(Navigation);